import React, {Component} from 'react';

class Credits extends Component {
    constructor(props) {
        super(props);

        this.state = {
            credits: this.props.credits
        }
    }

    handleChange = (event, index) => {
        event.preventDefault();

        let temp_array = this.state.credits;
        temp_array[index] = event.target.value;

        this.setState({credits: temp_array});

        //This updates the array in the parent object.
        this.props.onChange(this.props.fieldName, this.props.credits);
    }

    addCredit = (event) => {
        event.preventDefault();

        let temp_array = this.state.credits;
        temp_array.push("");

        this.setState({credits: temp_array});

        //This updates the array in the parent object.
        this.props.onChange(this.props.fieldName, this.props.credits);
    }

    removeCredit = (event, index) => {
        event.preventDefault();

        let temp_array = this.state.credits;
        temp_array.splice(index, 1);

        this.setState({credits: temp_array});

        //This updates the array in the parent object.
        this.props.onChange(this.props.fieldName, this.props.credits);
    }

    render() {
        return (
            <div>
                <div className="row">
                    <div className="col-12 col-sm-12">
                        <label className="m-0"><b>{this.props.fieldTitle}</b> {this.props.additionalCopy}</label>
                    </div>
                </div>
                {
                    this.state.credits.map((credit, index) => {
                        var item_key = this.props.fieldName + index;
            
                        return (
                            <div className="row pb-1" key={item_key}>
                                <div className="col-6">
                                    {
                                        (this.props.isRequired && (
                                            <input type="text" name={item_key} value={credit} className="form-control" required onChange={((event) => { this.handleChange(event, index) })} />
                                        ))
                                    }
                                    {
                                        (!this.props.isRequired && (
                                            <input type="text" name={item_key} value={credit} className="form-control" onChange={((event) => { this.handleChange(event, index) })} />
                                        ))
                                    }
                                   
                                </div>
                                <div className="col-6 d-none d-sm-none d-md-block">
                                    {
                                        ((index !== 0) && 
                                            <button className="btn btn-danger" style={{width: "170px"}} onClick={((event) => { this.removeCredit(event, index) })}>Remove {this.props.fieldTitleShort}</button>
                                        )
                                    }
                                    &nbsp;
                                    {
                                        ((index === this.state.credits.length - 1) &&
                                            <button className="btn btn-success" style={{width: "145px"}} onClick={this.addCredit}>Add {this.props.fieldTitleShort}</button>
                                        )
                                    }              
                                </div>
                                <div className="col-6 d-md-none d-lg-none d-xl-none">
                                    {
                                        ((index !== 0) && 
                                            <button className="btn btn-danger" style={{width: "100px"}} onClick={((event) => { this.removeCredit(event, index) })}>Remove</button>
                                        )
                                    }
                                    &nbsp;
                                    {
                                        ((index === this.state.credits.length - 1) &&
                                            <button className="btn btn-success" style={{width: "100px"}} onClick={this.addCredit}>Add</button>
                                        )
                                    }
                                </div>
                            </div>
                        );    
                    })
                }
                <br />
            </div>
        )
    } 
}

export default Credits;