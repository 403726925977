import React from 'react';
import './App.css';
import Home from './Home';

function App() {
  return (
    <div>    
      <div className="container hof-banner">
        <img src="../images/hof_header_1200x690.jpg" className="text-center img-fluid" alt="GRAMMY Hall of Fame" />
      </div>
      <div className="container hof-body">
          <Home />
      </div>
    </div>
  );
}

export default App;
