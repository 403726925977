import React, {Component} from 'react';
import config from './config';
import Credits from './components/Credits';
import LoadingPage from './components/LoadingPage';

class Home extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            maxValidYear: this.getMaxValidYear(),
            currentPage: "",
            genres: [],

            id: 0,
            artistName: "",
            title: "",
            genre: 0,
            recordLabel: "",
            catalogNumber: "",
            year: "",
            recordingType: "",
            arrangers: [""],
            songwriters: [""],
            producers: [""],
            engineers: [""],
            mixers: [""],
            groupMembers: [""],
            reason: "",
            submittedBy: "",
            email: "",
    
            selectedFiles: [],
            selectedFileError: "",

            /*
            artistName: "Joe Melendez",
            title: "Joe Melendez Rocks!",
            genre: 25,
            recordLabel: "TRA Records",
            catalogNumber: "ISBN1234567890",
            year: 1991,
            recordingType: "track",
            songwriters: ["Joe Melendez", "Joanna Chu"],
            producers: ["Kiyumi Nishida", "Michael Almanza"],
            engineers: ["Lisa Goich-Andreadis"],
            mixers: ["Shannon Herber", "Uziel Colon"],
            groupMembers: ["Joe Melendez", "Dave Grohl", "Paul McCartney"],
            reason: "reason",
            submittedBy: "submitted by",
            email: "joseph.melendez@grammy.com",
            */
        }
    }

    componentDidMount() {
        this.loadGenres();
    }

    resetForm() {
        this.setState({
            id: 0,
            artistName: "",
            title: "",
            genre: 0,
            recordLabel: "",
            catalogNumber: "",
            year: "",
            recordingType: "",
            arrangers: [""],
            songwriters: [""],
            producers: [""],
            engineers: [""],
            mixers: [""],
            groupMembers: [""],
            reason: "",
            submittedBy: "",
            email: "",
    
            selectedFiles: [],
            selectedFileError: "",
    
    /*
            artistName: "Joe Melendez",
            title: "Joe Melendez Rocks!",
            genre: 25,
            recordLabel: "TRA Records",
            catalogNumber: "ISBN1234567890",
            year: 1991,
            recordingType: "track",
            songwriters: ["Joe Melendez", "Joanna Chu"],
            producers: ["Kiyumi Nishida", "Michael Almanza"],
            engineers: ["Lisa Goich-Andreadis"],
            mixers: ["Shannon Herber", "Uziel Colon"],
            groupMembers: ["Joe Melendez", "Dave Grohl", "Paul McCartney"],
            reason: "reason",
            submittedBy: "submitted by",
            email: "joseph.melendez@grammy.com",
    */
        });
    }

    loadGenres() {
        let url = config.environment.application.baseUrl + '/api/hall-of-fame-entry/genres';

        fetch (url, {
            method: 'GET',
            headers: new Headers({
                'Content-Type': 'application/json'
            })
        }).then((response) => {
            return response.json();
        }).then((data) => {
            this.setState({genres: data})

            if (config.environment.application.environment !== "prod")
                console.log(data)
        }).catch((error) => {
            console.log(error);
        })
        .finally(() => {
            this.setState({isLoading: false});
        })
    }

    isFieldValid(field_name) {
        if (field_name === "artistName")
            return (this.state.artistName.length > 0);
        else if (field_name === "title")
            return (this.state.title.length > 0);
        else if (field_name === "genre")
            return (this.state.genre > 0);
        else if (field_name === "recordLabel")
            return (this.state.recordLabel.length > 0);
        else if (field_name === "catalogNumber")
            return (this.state.catalogNumber.length > 0);
        else if (field_name === "year")
            return (this.state.year !== "" && this.isYearValid());
        else if (field_name === "recordingType")
            return (this.state.recordingType.length > 0);
        else if (field_name === "reason")
            return (this.state.reason.length > 0);
        else if (field_name === "submittedBy")
            return (this.state.submittedBy.length > 0);
        else if (field_name === "email")
            return (this.isValidEmail());
        else
            return true;
    }

    isFormValid() {
        return (this.isFieldValid("artistName") &&
            this.isFieldValid("title") &&
            this.isFieldValid("genre") &&
            this.isFieldValid("recordLabel") &&
            this.isFieldValid("catalogNumber") &&
            this.isFieldValid("year") &&
            this.isFieldValid("recordingType") &&
            this.isFieldValid("reason") && 
            this.isFieldValid("submittedBy") &&
            this.isFieldValid("email")) &&
            //this.isCreditArrayValid(this.state.producers) &&
            //this.isCreditArrayValid(this.state.engineers) &&
            //this.isCreditArrayValid(this.state.mixers) &&
            (this.state.recordingType === "album" || this.isCreditArrayValid(this.state.songwriters)); 
            //Songwriters are only for singles and tracks, so if it is album, it is valid.
    }

    isCreditArrayValid(credit_array) {
        var return_value = true;

        for (var x = 0; x < credit_array.length; x++) {
            if (credit_array[x] === "")
                return false;
        }

        return return_value;
    }

    isValidEmail() {
        //This is just testing for basic functionality
        return /.+@.+\..+/.test(this.state.email);
    }

    requiredClassNames(field_name) {
        if (field_name === undefined || !this.isFieldValid(field_name))
            return "form-control required";
        else
            return "form-control";
    }

    isAlbum() {
        return (this.state.recordingType === "album");
    }

    getMaxValidYear() {
        var current_date = new Date();
        return current_date.getFullYear() - 24;
    }

    isYearValid() {
        //According to Wikipedia, the first ever recording was in 1860.
        return (this.state.year >= 1860 && this.state.year <= this.getMaxValidYear())
    }

    producerEngineerMixerCopy() {
        if (this.isAlbum())
            return "of 51% or more playing time of the album";
        else
            return "";
    }

    chooseFile = (e) => {
        window.document.getElementById("choose-file").click();
    }

    //For mulitple file updoads
    fileUploadHandler = (event) => {
        //Start by resetting the array.
        let selected_file_array = [];
        var total_file_size = 0;

        for (var x = 0; x < event.target.files.length; x++) {
            let selected_file = event.target.files[x];
            this.setState({ selectedFileError: "" });

            //We are limiting all the files combined to 25MB.
            total_file_size += selected_file.size;

            if (selected_file === undefined) {
                //They clicked cancel.  Let's do nothing here.
            }
            else if (total_file_size > config.MAX_ATTACHMENT_SIZE)
            {
                //Files are too large!
                event.target.value = "";
                selected_file_array = [];
                this.setState({ selectedFileError: "The maximum size of all files combined is 25MB" });

                break;
            }
            else if (!/(\.pdf|\.doc|\.docx|\.jpg|\.gif|\.png)$/i.test(selected_file.name)) {
            //else if (!/(\.pdf)$/i.test(selected_file.name)) {
                //Bad file extension.
                event.target.value = "";
                selected_file_array = [];
                this.setState({ selectedFileError: "Valid file types are: PDF, DOC, DOCX, JPG, GIF, PNG" });

                break;
            }
            else {
                //It's a good size and file extension.
                selected_file_array.push(selected_file);
            }
        }

        if (config.environment.application.environment !== "prod")
            console.log(selected_file_array);

        this.setState({ selectedFiles: selected_file_array });
    }

    selectedFilesDisplayString() {
        if (this.state.selectedFiles === [])
            return "";
        
        var file_string = "";

        for (var x = 0; x < this.state.selectedFiles.length; x++) {
            if (x > 0)
                file_string += ", ";

            file_string += this.state.selectedFiles[x].name;
        }

        return file_string;
    }

    submitForm = (event) => {
        event.preventDefault();

        this.saveEntryToDatabase().then((response) => {
            if (!response) {
                console.log("An error occured while saving entry.");
            }
            else {
                //Everything went correctly, so show Thank You and re-initialize the form for the member to do it again if they want.
                this.setState({ currentPage: "ThankYou" });
                this.resetForm();
            }
        });
    }

    async saveEntryToDatabase() {
        this.setState({isLoading: true});
        let return_value = true;
        let url = config.environment.application.baseUrl + "/api/hall-of-fame-entry/save";

        if (config.environment.application.environment !== "prod")
            console.log(url);

        let hall_of_fame_entry = {
            artistName: this.state.artistName,
            title: this.state.title,
            genreID: this.state.genre,
            recordLabel: this.state.recordLabel,
            catalogNumber: this.state.catalogNumber,
            year: this.state.year,
            recordingType: this.state.recordingType,
            arrangers: this.state.arrangers,
            songwriters: this.state.songwriters,
            producers: this.state.producers,
            engineers: this.state.engineers,
            mixers: this.state.mixers,
            groupMembers: this.state.groupMembers,
            reason: this.state.reason,
            submittedBy: this.state.submittedBy,
            email: this.state.email
        };

        if (config.environment.application.environment !== "prod")
            console.log(hall_of_fame_entry);

        await fetch(url, {
            method: 'POST',
            headers: new Headers({
              'Accept': 'application/json',
              'Content-Type': 'application/json',
            }),
            body: JSON.stringify(hall_of_fame_entry)
        })
        .then((response) => {
            return response.json();
        })
        .then((data) => {
            if (config.environment.application.environment !== "prod")
                console.log(data);

            this.setState({
                id: data,
            });
        })
        .catch((error) => console.log("In Error : " + error));

        if (!(this.state.id > 0))
            return_value = false;

        //Now, upload the supporting files, if they exists.
        if (this.state.selectedFiles.length > 0) {
            const data = new FormData();

            for (var x = 0; x < this.state.selectedFiles.length; x++) {
                if (config.environment.application.environment !== "prod")
                    console.log(this.state.selectedFiles[x]);

                data.append('file[' + x + ']', this.state.selectedFiles[x]);
            }
    
            if (return_value) {
                //Modify the URL to upload the file.
                url += "/" + this.state.id + "/uploadFile";
    
                await fetch(url, {
                    method: 'POST',
                    body: data
                })
                .then((response) => {
                    return response.json();
                })
                .then((response) => {
                    if (config.environment.application.environment !== "prod")
                        console.log(response);
    
                    if (response !== "")
                        return_value = false;
                })
                .catch((error) => console.log(error));
            }
        }

        this.setState({isLoading: false});

        return return_value;
    }

    handleChange = (event) => {
        event.preventDefault();
        //console.log(event.target.name + " = " + event.target.value);
        this.setState({ [event.target.name]: event.target.value });
    }

    handleCreditsChange = (field_name, array) => {
        //console.log("handleCreditsChange: " + field_name + " = " + array);

        if (field_name === "songwriter")
            this.setState({ songwriters: array });
        else if (field_name ==="arranger")
            this.setState({ arrangers: array });
        else if (field_name ==="producer")
            this.setState({ producers: array });
        else if (field_name ==="engineer")
            this.setState({ engineers: array });
        else if (field_name ==="mixer")
            this.setState({ mixers: array });
        else if (field_name ==="groupmember")
            this.setState({ groupMembers: array });
        else
            console.log("Not found: " + field_name);
    }

    makeAnotherEntry = (event) => {
        event.preventDefault();
        this.setState({ currentPage: "" });
    }

    thankYouMessage() {
        console.log("thank you")
        return (
            <div className="row">
                <div className="col-12 col-sm-12">
                    Thank you for your entry to the GRAMMY Hall of Fame. If we have any questions regarding your entry, or need more information, we will contact you at the email address you provided. If submitting more entries, please keep in mind that there is a limit of 10 submissions per person, per year.<br />
                    <br />
                    <button className="form-control btn btn-primary" onClick={this.makeAnotherEntry}>Make Another Hall of Fame Entry</button>
                </div>
            </div>
        )
    } 

    pageHeader() {
        return (
            <div className="text-center">
                {
                    // ((config.environment.application.environment !== "prod") && (
                    // <div style={{color: "red"}}><b><h3>{config.environment.application.environment} {config.environment.application.environment} {config.environment.application.environment} {config.environment.application.environment} {config.environment.application.environment}</h3></b></div>
                    // ))
                }
                <br />
                <h1 className="hof-header">Recommendation 2025</h1>
                <h2 className="hof-subheader">
                    For recordings released through 2000<br />
                    SUBMISSIONS DUE: October 1, 2024
                </h2>
            </div>
        )
    }

    render() {
        if (this.state.currentPage === "ThankYou") {
            return (
                <div>
                    {this.pageHeader()}
                    <br />
                    <br />
                    {this.thankYouMessage()}
                    <br />
                    <br />
                    <br />
                    <br />
                </div>
            )
        }

        return (
            <div className="hall-of-fame-form">
                {this.pageHeader()}
                <br />
                <div>
                    <p>We are now soliciting recommendations for the 2025 GRAMMY Hall of Fame.</p>
                    
                    <p>The GRAMMY Hall Of Fame was established by the Recording Academy's National Trustees in 1973 with the noble purpose of honoring recordings of lasting qualitative or historical significance that are at least 25 years old. Inductees are selected annually by a special member committee of eminent and knowledgeable professionals from all branches of the recording arts. The Recording Academy holds this award in very high esteem and looks forward to your submissions for this very important honor.</p>

                    <p>All submissions must meet our basic criteria:</p>
                    <ol>
                        <li>Recordings must be at least 25 years old (counting back from 2025 and released 2000 or prior)</li>
                        <li>A submission can’t have been previously inducted into the Hall of Fame.</li>
                        <li>You can find all of the past Hall of Fame inductees here: <a target="_blank" rel="noopener noreferrer" href="https://www.grammy.com/awards/hall-of-fame-award">https://www.grammy.com/awards/hall-of-fame-award</a>.</li>
                        <li><b>Singles or tracks from albums already inducted into the Hall of Fame are not eligible.</b></li>
                    </ol>

                    <p>Please fill out the form below. <b>Please complete the entire form</b>, including the reasons for recommendation. Please fill in ALL fields, including a .pdf of the original album cover and liner notes, if available. The deadline for submissions is <span style={{color: "red"}}><b>Monday, October 1, 2024</b></span>.</p>
                    
                    <p style={{color: "red"}}><b>Any ineligible or incomplete entries will be removed without notification.</b></p>
                    <p>Important note:</p>
                    <ol>
                        <li>Please type your primary reasons for induction using upper and lower case. <b>Do not type in all caps.</b></li>
                        <li>Please double check that any singles or tracks submitted are not part of a previously inducted album.</li>
                        <li>Use catalog numbers, when available, to differentiate between releases.</li>
                        <li>Thank you for your participation in this process!</li>
                    </ol>
                    Direct any questions to <a href="mailto:halloffame@grammy.com">halloffame@grammy.com</a>.
                </div>
                <br />
                <br />
                <h5>Information Must Be Provided And Verified</h5>
                <div className="row">
                    <div className="col-12 col-md-6">
                        <label htmlFor="artistName" className="m-0"><b>Artist Name</b></label>
                        <input type="text" name="artistName" value={this.state.artistName} onChange={this.handleChange} required className="form-control" />
                    </div>
                    <div className="col-12 col-md-6">
                        <label htmlFor="title" className="m-0"><b>Title of Recording</b></label>
                        <input type="text" name="title" value={this.state.title} onChange={this.handleChange} required className="form-control" />
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 col-md-6">
                        <label htmlFor="genre" className="m-0"><b>Genre</b></label>
                        <select name="genre" value={this.state.genre} onChange={this.handleChange} required className="form-control">
                            <option value="">Select...</option>
                            {
                                    this.state.genres.map((current_genre) => {
                                        return <option key={current_genre.id} value={current_genre.id}>{current_genre.description}</option>
                                    })
                            }
                        </select>
                    </div>
                    <div className="col-12 col-md-6">
                        <label htmlFor="recordLabel" className="m-0"><b>Record Label</b></label>
                        <input type="text" name="recordLabel" value={this.state.recordLabel} onChange={this.handleChange} required className="form-control" />
                    </div>
                </div>
                <div className="row">
                    <div className="col-6">
                        <label htmlFor="catalogNumber" className="m-0"><b>Catalog Number</b></label>
                        <input type="text" name="catalogNumber" value={this.state.catalogNumber} onChange={this.handleChange} required className="form-control" />
                    </div>
                    <div className="col-6">
                        <label htmlFor="year" className="m-0"><b>Year of Release</b></label>
                        <input type="number" name="year" value={this.state.year} onChange={this.handleChange} min="1860" max={this.getMaxValidYear()} required className="form-control" />
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <label htmlFor="reason" className="m-0"><b>State primary reasons why this recording should be inducted into the Hall of Fame. This statement is mandatory and should be no more than 500 characters in length.</b></label>
                        <textarea name="reason" value={this.state.reason} onChange={this.handleChange} required className="form-control" rows="6" maxLength="500"></textarea>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 col-sm-12">
                        <br />
                        <b>If possible, please attach a PDF file of the album cover image, label and liner notes.</b><br />
                        <input style={{display: "none"}} id="choose-file" type="file" onChange={this.fileUploadHandler} multiple />
                        <input type="button" className={"btn " + (this.state.selectedFiles.length === 0 ? "btn-primary" : "btn-secondary")} value="Browse for Files" onClick={this.chooseFile} />&nbsp;&nbsp;
                        <span><b>Selected file(s): </b>{this.selectedFilesDisplayString()}</span><span style={{color: "red"}}>{this.state.selectedFileError}</span><br />
                        <br />
                        Valid file types are: PDF, DOC, DOCX, JPG, GIF, PNG<br />
                        The maximum size of all files combined is 25MB.<br />
                        <br />
                    </div>
                </div>
                <h5>Album or Single/Track (select one and complete information)</h5>
                <div className="row">
                    <div className="col-6 col-sm-6 col-md-5 col-lg-4">
                        <label htmlFor="recordingType" className="m-0"><b>Recording Type</b></label>
                        <select name="recordingType" value={this.state.recordingType} onChange={this.handleChange} required className="form-control">
                            <option value="">Select...</option>
                            <option value="album">Album</option>
                            <option value="single">Single</option>
                            <option value="track">Track</option>
                        </select>
                    </div>
                </div>
                {
                    (this.state.recordingType !== "" && !this.isAlbum() && (
                        <div className="row">
                            <div className="col-12">
                                <b>Note: When submitting singles or tracks, please verify that the recording was not part of a previously inducted album.</b><br />
                            </div>
                        </div>
                    ))
                }
                <br />
                {
                    ((this.state.recordingType !== "") && (
                        <div>
                            <p>Please list one name per line in the fields below.  For multiple names within the same field, click the green &quot;Add&quot; button to the right.</p>
                            <Credits fieldTitle="Group Member(s)" fieldTitleShort="Member" fieldName="groupmember" credits={this.state.groupMembers} onChange={this.handleCreditsChange} additionalCopy="(if applicable)" />        
                            <Credits fieldTitle="Producer(s)" fieldTitleShort="Producer" fieldName="producer"  credits={this.state.producers} onChange={this.handleCreditsChange} additionalCopy={this.producerEngineerMixerCopy()} />
                            <Credits fieldTitle="Engineer(s)/Mixer(s)" fieldTitleShort="Engineer" fieldName="engineer"  credits={this.state.engineers} onChange={this.handleCreditsChange} additionalCopy={this.producerEngineerMixerCopy()} />
                            <Credits fieldTitle="Songwriter(s)" fieldTitleShort="Songwriter" fieldName="songwriter" credits={this.state.songwriters} onChange={this.handleCreditsChange} additionalCopy={this.producerEngineerMixerCopy()} />
                            <Credits fieldTitle="Arrangers(s)" fieldTitleShort="Arranger" fieldName="arranger"  credits={this.state.arrangers} onChange={this.handleCreditsChange} additionalCopy={this.producerEngineerMixerCopy()} />
                        </div>
                    ))
                }

                <br />

                <div className="row">
                    <div className="col-12 col-md-6">
                        <label htmlFor="submittedBy" className="m-0"><b>Submitted By</b></label>
                        <input type="text" name="submittedBy" value={this.state.submittedBy} onChange={this.handleChange} required className="form-control" />
                    </div>
                    <div className="col-12 col-md-6">
                        <label htmlFor="email" className="m-0"><b>Email Address</b></label>
                        <input type="text" name="email" value={this.state.email} onChange={this.handleChange} required className="form-control" pattern=".+@.+\..+" />
                    </div>
                </div>

                <br />
                <br />

                <button className="form-control btn btn-primary" disabled={!this.isFormValid() || this.state.isLoading} onClick={this.submitForm}>Submit Hall of Fame Entry</button>

                <br />
                <br />
                <br />
                <br />

                <LoadingPage show={this.state.isLoading} />
            </div>
        );
    }
}

export default Home;