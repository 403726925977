import React, { Component } from 'react';

class LoadingPage extends Component {
    render() {
        if (this.props.show) {
            return (
                <div className="spinner-background">
                    <div className="spinner">
                        <img src="images/Spin-2s-72px.gif" alt="Loading..." />
                    </div>
                </div>
            );
        }
        else
            return null;
    }
}

export default LoadingPage;
